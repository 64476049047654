<template>
  <div class="col-sm-12 col-md-12 col-lg-3 fd-side-menu-bg pc">
    <div
      id="menu_lateral"
      class="fd-side-menu"
    >
      <ul
        id="accordionSideMenu"
        class="fd-side-menu-nav-list"
      >
        <li
          class="fd-side-menu-nav-list-item"
          data-toggle="collapse"
          role="button"
          aria-expanded="false"
          aria-controls="collapseCursos"
        >
          <a :href="'/plataforma/' + $route.params.id_plataforma + '/admin/meus-cursos'">Cursos</a>
        </li>
        <!--
        <div class="collapse" id="collapseCursos" data-parent="#accordionSideMenu">
            <ul class="fd-side-menu-subnav-list">
                <li class="fd-side-menu-subnav-list-item"><a href="modo_admin_meus_cursos.html">Criar Curso</a></li>

                <li class="fd-side-menu-subnav-list-item"><a href="#" data-toggle="modal" data-target="#novo-curso">Criar Curso</a></li>

                <li class="fd-side-menu-subnav-list-item"><a href="#" data-toggle="modal" data-target="#importar-novo-curso">Importar Curso</a></li>
                <li class="fd-side-menu-subnav-list-item"><a href="#">Marketplace de Curso</a></li>

    </ul>
        </div>
        -->
        <li
          class="fd-side-menu-nav-list-item"
          data-toggle="collapse"
          href="#"
          role="button"
          aria-expanded="false"
          aria-controls="collapseUsuarios"
        >
          <a :href="'/plataforma/' + $route.params.id_plataforma + '/admin/usuarios'">Usuário</a>
        </li>
        <!--
    <div class="collapse" id="collapseUsuarios" data-parent="#accordionSideMenu">
        <ul class="fd-side-menu-subnav-list">
            <li class="fd-side-menu-subnav-list-item"><a href="modo_admin_plataforma_usuarios.html">Convidar Usuários</a></li>

            <li class="fd-side-menu-subnav-list-item"><a href="#" data-toggle="modal" data-target="#convidar-usuarios">Convidar Usuários</a></li>

            <li class="fd-side-menu-subnav-list-item"><a href="#" data-toggle="modal" data-target="#importar-usuarios">Importar</a></li>

        </ul>
    </div>
        -->
        <li
          class="fd-side-menu-nav-list-item"
          data-toggle="collapse"
          href="#"
          role="button"
          aria-expanded="false"
          aria-controls="collapsePersonalizar"
        >
          <a :href="'/plataforma/' + $route.params.id_plataforma + '/admin/personalizar-plataforma'">Personalizar</a>
        </li>
        <!--
    <div class="collapse" id="collapsePersonalizar" data-parent="#accordionSideMenu">
        <ul class="fd-side-menu-subnav-list">
            <li class="fd-side-menu-subnav-list-item"><a href="#" data-toggle="modal" data-target="#modalInserirLogoPlataforma">Inserir Logo</a></li>

            <li class="fd-side-menu-subnav-list-item"><a href="#">Home Cursos</a></li>
            <li class="fd-side-menu-subnav-list-item"><a href="#">Emails</a></li>
            <li class="fd-side-menu-subnav-list-item"><a href="#">Área de Atendimento</a></li>
        </ul>
    </div>
        -->
        <li
          class="fd-side-menu-nav-list-item"
          data-toggle="collapse"
          href="#"
          role="button"
          aria-expanded="false"
          aria-controls="collapseMatriz"
        >
          <a :href="'/plataforma/' + $route.params.id_plataforma + '/admin/matriz-capacitacao'">Matriz de Capacitação</a>
        </li>
        <!--
    <div class="collapse" id="collapseMatriz" data-parent="#accordionSideMenu">
        <ul class="fd-side-menu-subnav-list">
            <li class="fd-side-menu-subnav-list-item"><a href="#">Setor</a></li>
            <li class="fd-side-menu-subnav-list-item"><a href="#">Cargo</a></li>
            <li class="fd-side-menu-subnav-list-item"><a href="#">Lista Matriz</a></li>
            <li class="fd-side-menu-subnav-list-item"><a href="#">Certificado</a></li>
        </ul>
    </div>
        -->
        <li
          class="fd-side-menu-nav-list-item"
          data-toggle="collapse"
          href="#"
          role="button"
          aria-expanded="false"
          aria-controls="collapseFinanceiro"
        >
          <a :href="'/plataforma/' + $route.params.id_plataforma + '/admin/financeiro'">Financeiro</a>
        </li>
      </ul>
    </div>
    <div class="fd-side-menu-border" />
  </div>
</template>

<script>
export default {};
</script>

<style></style>
